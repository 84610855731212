@font-face {
  font-family: "Lexend";
  src: url("./fonts/lexend/Lexend-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Lexend";
  src: url("./fonts/lexend/Lexend-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Lexend";
  src: url("./fonts/lexend/Lexend-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f9;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

/**
* Disable browser styling for autocomplete input
**/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.swiper-pagination-bullet-active {
  background-color: #6c57ed !important;
}

.swiper-container {
  width: 480px;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

/* the slides */
.slick-slide {
  margin: 0 15px;
  flex-wrap: nowrap;
}

/* the parent */
.slick-list {
  /* margin: 0 -27px; */
}
